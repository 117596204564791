<template>
	<div>
		<!-- Page is loaded -->
		<ion-grid v-if="pageId > 0">
			<!-- No Image -->
			<ion-row v-if="pageImg === null && imageUrl === null" class="ion-justify-content-center ion-padding-bottom padding-top-md">
				<ion-avatar class="ion-text-center">
					<ion-img  src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
				</ion-avatar>					
			</ion-row>
			<!-- Image has just been uploaded -->
			<ion-row v-if="pageImg === null && imageUrl != null" class="ion-justify-content-center ion-padding-bottom">
				{{imageUrl}}					
			</ion-row>
			<!-- Image passed in -->
			<ion-row v-if="pageImg != null && imageUrl === null" class="ion-justify-content-center ion-padding-bottom">
				<ion-avatar class="ion-text-center">
					<ion-img  :src="pageImg" />
				</ion-avatar>						
			</ion-row>
			<!-- Image passed in and image uploaded -->
			<ion-row v-if="pageImg != null && imageUrl != null" class="ion-justify-content-center ion-padding-bottom">
				{{imageUrl}}					
			</ion-row>
			<!-- Take Picture -->
			<ion-row v-if="imageUrl === null" class="ion-justify-content-center ion-padding-bottom">	
				<ion-button @click="takePicture()">
					<ion-icon :icon="image"></ion-icon>
				</ion-button>
			</ion-row>			
		</ion-grid>
		
	</div>
</template>

<script>
import { IonGrid, IonRow, IonAvatar, IonImg, IonIcon, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { image, trash } from 'ionicons/icons';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
	name: 'PagePhoto',
	props: ['pageId', 'pageImg', 'postType'],
	components: {
		IonGrid, IonRow, IonAvatar, IonImg, IonIcon, IonButton
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const imageUrl = ref(null);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const isError = ref(false);
		const errors = ref([]);

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		function storePhoto() {
			presentLoading()
			// Send auth token
			apiClient.post('/api/update/page-photo', {
					page_id: props.pageId,
					image: this.imageUrl
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					console.log(response)
					if(response.status === 200) {
						// Success
						if(response.data.message == 'success') {
							toastMessage.value = 'You have updated your profile photo.'
							if(props.postType == 'create') {
								this.dataUp()
							} else {
								window.location = '/admin-page/' + props.pageId
							}
							
							openToast()
							resetForm()
						}
						// Fail Validation
						if(response.data.message == 'fail') {
							toastMessage.value = 'Something went wrong.'
							openToast()          
						}
						// Fail Validation
						if(response.data.message == 'fail-validation') {
							toastMessage.value = 'Something went wrong.'
							openToast()
							isError.value = true
							errors.value = response.data.errors            
						}
						// Fail Auth
						if(response.data.message == 'fail-auth') {
							toastMessage.value = 'You must be signed in to update your profile.'
							openToast()
						}
					}
				}).catch(error => {
					console.log(error)  
					isError.value = true
					errors.value = error.response.data.errors
				});
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function resetForm() {
			imageUrl.value = null
		}
		
		return {
			image, trash, takePicture, imageUrl, storePhoto, authUser, isError, errors
		}
	},
	methods: {
		dataUp: function() {
			this.$emit('next-step', 1)
		}
	},
	watch: {
		imageUrl: function() {
			if(this.imageUrl != null && this.imageUrl != '') {
				this.storePhoto()
			}
		}
	}
});
</script>

<style scoped>
	ion-avatar {
		width:180px !important;
		height: 180px !important;
		max-width: 180px !important;  
		max-height: 180px !important; 
	}
</style>