<template>
	<div>
		<!-- Button -->
		<ion-button @click.prevent="storePageGenres()">
			Save
		</ion-button>
		<!-- If Genres -->
		<div v-if="genres.length > 0">
			<ion-item v-for="genre in genres" :key="genre" button @click.prevent="updateItems(genre)">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-icon v-if="genre.isChecked === false" :icon="squareOutline" color="dark"></ion-icon>
				<ion-icon v-if="genre.isChecked === true" :icon="checkbox" color="success"></ion-icon> 
				<ion-text class="margin-left-md">{{genre.name}}</ion-text>
			</ion-item>
		</div>
	</div>
</template>

<script>
import { IonItem, IonIcon, IonText, IonButton, loadingController, toastController } from '@ionic/vue';
import { squareOutline, checkbox } from 'ionicons/icons';
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'PageGenres',
	props: ['pageId', 'postType'],
	components: {
		IonItem, IonIcon, IonText, IonButton
	},
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const genres = ref([]);
		const checkedGenres = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }

		onMounted(() => {
			getPageGenres()
			getGenres()
		})

		function getGenres() {
			presentLoading()
			apiClient.get('/api/get/genres', {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				let tempGenres = response.data

				if(tempGenres.length > 0) {
					for(var i=0;i<tempGenres.length;i++) {

						genres.value.push({
							id: tempGenres[i].id,
							name: tempGenres[i].name,
							slug: tempGenres[i].slug,
							isChecked: isChecked(tempGenres[i].id)
						})
					}
				}

			}).catch(error => {
				console.log(error)  
			});      
		}

		function getPageGenres() {
			presentLoading()
			apiClient.get('/api/get/page-genres/'+props.pageId, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				if(response.data.length > 0) {
					for(var i=0;i<response.data.length;i++) {
						checkedGenres.value.push({
							id: response.data[i].id,
							name: response.data[i].name,
							slug: response.data[i].slug,
						})
					}
				}
			}).catch(error => {
				console.log(error)  
			});      
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function isChecked(id) {
			var inChecked = false
			if(checkedGenres.value.length > 0) {
				for(var i=0;i<checkedGenres.value.length;i++) {
					if(checkedGenres.value[i].id == id) {
						inChecked = true
					}
				}        
			}
			return inChecked
		}

		function updateItems(item) {
			var checked = false
			// add or remove from store
			if(checkedGenres.value.length > 0) {
				checked = isChecked(item.id)
			}
			// Remove from store
			if(checked === true) {
				if(checkedGenres.value.length > 0) {
					for(var i=0;i<checkedGenres.value.length;i++) {
						if(checkedGenres.value[i].id == item.id) {
							// Remove
							doCheck(checkedGenres.value[i].id, false)
							checkedGenres.value.splice(i, 1)
						}
					}        
				}        
			} 
			// Add to checked
			else {
				//checkedGenres.value = []
				doCheck(item.id, true)
				// Add
				checkedGenres.value.push({
					id: item.id,
					name: item.name,
					slug: item.slug,
				})
			}
		}

		function doCheck(id, val) {
			if(genres.value) {
				for(var i=0;i<genres.value.length;i++) {
					if(genres.value[i].id == id) {
						genres.value[i].isChecked = val
					}
				}
			}
		}

		function storePageGenres() {
			presentLoading()
			apiClient.post('/api/update/page-genres/'+props.pageId, 
			{
				genres: checkedGenres.value, 
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have updated page genres.'
					if(props.postType == 'create') {
						window.location = '/admin-pages'
					}
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'         
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'          
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That hashtag is already taken.'
				}
				openToast()
			})
			.catch(error => {
				console.log(error)
			}); 
		}

		return {
			authUser, genres, checkedGenres, squareOutline, checkbox, router, openToast, updateItems, storePageGenres
		}
	},

});
</script>